<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="warning" @click="addMec">添加店铺</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
    <template #handler={row}>
        <div>
            <el-button type="primary" @click="details(row)">详情</el-button> 
               <el-button type="success" @click="update(row)">更新</el-button> 
        </div>
    </template>
    </auto-table>
    <editCoachMec ref="mec" @success="getList(1)"></editCoachMec>
  </div>
</template>

<script>
import editCoachMec from "./components/editCoachMec.vue";

export default {
  components: {
    editCoachMec,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "mechanism_name" },
        { name: "联系人", value: "contacts" },
        { name: "城市", value: "city" },
        { name: "处理人", value: "handler_name" },
        { name: "类型", value: "status", type: "custom" },
        { name: "处理时间", value: "update_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    details(v){
        //  this.$router.push({name:'coachMeDetails',query:{id:v.id}});
           this.$router.push({name:'mecDetails',query:{mecID:v.id}});
    },
update(v){
  this.$refs.mec.update(v.id)
},
    addMec(){
        this.$refs.mec.open()
    },

    //列表
    getList(v) {
      let page = v || 1;
      let params = {
        currentPage: page,
        pageSize: 10,
        type: 'sign_coach_mec',
      };

      this.$axios({
        url: "/user/mastermechanism/queryManagerListPage",
        params,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style scoped>
.ell {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>