<template>
  <div>
    <el-dialog title="签约教练店铺" :visible.sync="dialogVisible" width="50%">
      <el-form>
        <div class="flex">
          <div class="half">
            <el-form-item v-if="mode == 'add'"  label="签约教练" required>
              <coachSelect
                @change="changeCoach"
                :coachid.sync="coach_id"
                :condition="{ coach_role: 'sign' }"
              ></coachSelect>
            </el-form-item>
            <el-form-item v-if="mode == 'add'"  label="签约小区" required>
              <mechanism-select
                :mecid.sync="mecid"
                :condition="{ is_community: true }"
                @change="changeMec"
              ></mechanism-select>
            </el-form-item>
            <el-form-item label="店铺名称" required>
              <el-input v-model="form.mechanism_name"></el-input>
            </el-form-item>
            <el-form-item label="是否推荐">
              <el-radio-group v-model="form.is_recommend">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="详细地址" required>
              {{ form.mechanism_addr }}
            </el-form-item>
            <el-form-item label="店铺电话" required>
              <el-input v-model="form.mechanism_telephone"></el-input>
            </el-form-item>
            <el-form-item label="负责人姓名" required>
              <el-input v-model="form.contacts"></el-input>
            </el-form-item>
            <el-form-item
              label="联系电话"
              required
            >
              <el-input v-model="form.contact_telephone"></el-input>
            </el-form-item>
            <el-form-item label="选择子类目" required>
              <el-popover
                placement="top-start"
                title="标题"
                trigger="click"
                v-model="visible"
              >
                <el-button slot="reference">{{
                  haveCate.length == 0 ? "选择子类目" : haveCate.join(",")
                }}</el-button>

                <el-transfer
                  filterable
                  filter-placeholder="请选择绑定的科目"
                  v-model="haveCate"
                  :data="categories_child_list"
                  :props="{
                    key: 'name',
                    label: 'name',
                  }"
                  :titles="['所有类目', '拥有类目']"
                >
                </el-transfer>
              </el-popover>
            </el-form-item>
            <el-form-item label="店铺简介">
              <el-input
                style="width: inherit"
                type="textarea"
                v-model="form.introduction_content"
              ></el-input>
            </el-form-item>
          </div>
          <div class="half">
            <el-form-item label="店铺logo">
              <avatar-uploader
                @getImgUrl="(v) => (form.logo = v)"
                :clear="if_clear"
                :url="temp.logo"
              ></avatar-uploader>
            </el-form-item>
            <el-form-item label="营业执照" prop="support_means" ref="support">
              <images-uploader
                @getImgUrl="(v) => (form.support_means = v)"
                :clear="if_clear"
                :urls="temp.support_means"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="店铺资质">
              <images-uploader
                @getImgUrl="(v) => (form.get_quali_pic_url = v)"
                :clear="if_clear"
                :urls="temp.qualifications_pic"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="简介图片">
              <images-uploader
                @getImgUrl="(v) => (form.introduction_pic = v)"
                :clear="if_clear"
                :urls="temp.introduction_pic"
              >
              </images-uploader>
            </el-form-item>
            <el-form-item label="店铺门面图">
              <images-uploader
                @getImgUrl="(v) => (form.facade_view = v)"
                :clear="if_clear"
                :urls="temp.facade_view"
              >
              </images-uploader>
            </el-form-item>
          </div>
        </div>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="mode == 'add'" type="primary" @click="insertMec"
          >确 定</el-button
        >
         <el-button v-if="mode == 'update'" type="primary" @click="updateMec"
          >更新</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import coachSelect from "../../../components/select/coachSelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  components: {
    coachSelect,
  },
  data() {
    return {
      dialogVisible: false,
      form: {},
      temp: {},
      coach_id: "",
      categories_child_list: [],
      haveCate: [],
      visible: false,
      mecid: "",
      if_clear: false,
      mode:'add'
    };
  },

  mounted() {
    this.getCategoriesList();
  },

  methods: {
    open(){
        this.form = {}
         this.temp = {}
          this.mode = 'add'
        this.dialogVisible = true
    },
    update(id){
        this.mode = 'update'
         let url = '/user/mastermechanism/findById'
        let data ={
         id
        }
       this.$get(url,data).then(res=>{
        let info = res.data.data
        this.temp = Object.assign({},info)
        this.form = info
         this.dialogVisible = true
       }
       )
    },



updateMec(){
      let url = '/user/mastermechanism/update'
     const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.$axios.post(url, { id: this.form.id, ...data }).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "修改成功!" });
          this.dialogVisible = false;
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
},

    insertMec(){
        let url = '/user/mastermechanism/insert'
        let data ={
            ...this.form,
            type:'sign_coach_mec',
            sign_coach_id:this.coach_id,
            sign_community_id:this.mecid,
            status:2
        }
       this.$post(url,data).then(res=>{
        this.$emit('success', res);
        this.dialogVisible = false
       }
       )
    },


//选择教练
    changeCoach(v) {
      this.$nextTick(() => {
        this.form.mechanism_telephone = v.phone;
        this.form.contact_telephone = v.phone;
        this.form.contacts = v.real_name;
        this.haveCate = v.teaching_categories.split(",");
      });
    },
    //选择小区
    changeMec(v) {
      this.$nextTick(() => {
        this.form.city = v.city;
        this.form.district = v.district;
        this.form.province = v.province;
        this.form.longitude = v.longitude;
        this.form.latitude = v.latitude;
        this.form.mechanism_addr = v.mechanism_addr;
      });
    },

    //类目
    getCategoriesList() {
      let url = "/user/mechanismCategory/queryListPageChild";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: 2,
            source: "课程",
          },
        })
        .then((res) => {
          this.categories_child_list = res.data.data;
        });
    },
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}
</style>