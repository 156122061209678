import { render, staticRenderFns } from "./editCoachMec.vue?vue&type=template&id=2d345980&scoped=true&"
import script from "./editCoachMec.vue?vue&type=script&lang=js&"
export * from "./editCoachMec.vue?vue&type=script&lang=js&"
import style0 from "./editCoachMec.vue?vue&type=style&index=0&id=2d345980&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d345980",
  null
  
)

export default component.exports